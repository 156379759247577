import React from 'react';
import { graphql } from 'gatsby';
import {
  Intro,
  Lead,
  Swirl,
  Section,
  Prefooter,
  Card,
  CardList,
  Grid,
  Content
} from '../partials/components';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { PageTransition } from '../helpers/pageTransitions';
import { FaChevronRight } from 'react-icons/fa';
import { t } from '../helpers/language';
import { getColor } from '../helpers/colors';

const ProductLanding = ({ data, pageContext }) => {
  const {
    title,
    leadText,
    seoMetaTags,
    collectionGroup,
    extendedContentNode,
    pathways,
    showPathways
  } = data.landing;

  const { contactPage, defaultPathways } = data.globalConfig;

  const allPathways = [...pathways, ...defaultPathways].slice(0, 3);

  return (
    <PageTransition>
      <HelmetDatoCms seo={seoMetaTags} />
      <Section>
        <Intro
          title={title}
          breadcrumbs={pageContext.breadcrumbs}
          action={{
            iconAfter: <FaChevronRight />,
            gaTarget: 'spa-chooser-cta',
            children: t('spa_chooser_cta'),
            to: '/spa-chooser'
          }}
        >
          <Swirl toColor={getColor('light-gray')} />
        </Intro>
        {leadText && <Lead body={leadText} />}
      </Section>
      {collectionGroup.map(
        ({ id, collections, groupHeading, groupDescription }, i) => (
          <Section
            animate={true}
            key={`${id}-${i}`}
            heading={groupHeading}
            lead={groupDescription}
            padding={'double'}
            background={'light-gray'}
          >
            <Grid
              children={collections.map(collection => (
                <Card
                  key={`${id}-${i}-${collection.id}`}
                  image={collection.image}
                  heading={collection.title}
                  caption={collection.leadText}
                  list={collection.salesFeatures}
                  links={[
                    {
                      path: collection.path,
                      title: `View ${collection.title}`
                    }
                  ]}
                  buttonsRight={true}
                />
              ))}
            />
          </Section>
        )
      )}
      {extendedContentNode && (
        <Section padding={'both'} background={'light-gray'}>
          <Content contentNode={extendedContentNode} />
        </Section>
      )}
      {showPathways && (
        <Section animate={true} background={'light-gray'} padding={'both'}>
          <CardList fullWidth={true}>
            {allPathways.map((pathway, i) => (
              <Card key={`pathway-${i}`} {...pathway} />
            ))}
          </CardList>
        </Section>
      )}
      <Section animate={true} background={'light-gray'} padding={'top'}>
        <Prefooter contactPagePath={contactPage.path} />
      </Section>{' '}
    </PageTransition>
  );
};

export const query = graphql`
  query productLandingById($id: String, $language: String) {
    landing: datoCmsProductLandingPage(id: { eq: $id }) {
      slug
      id
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      extendedContentNode {
        childMarkdownRemark {
          html
        }
      }
      collectionGroup {
        id
        collections {
          ...CollectionCard
        }
        groupHeading
        groupDescription
      }
      pathways {
        ...Card
      }
      showPathways
    }
    globalConfig: datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
      wetTestPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`;

export default ProductLanding;
